let _baseUrl;
if (!!localStorage.getItem("baseUrl"))
  _baseUrl = JSON.parse(localStorage.getItem("baseUrl"));
else _baseUrl = "https://stgtrading.de/gift/public/dashboard/api";

const BASE_URL = {

  // PUBLIC_BASE_URL: "https://stgtrading.de/hadeya-react/public/dashboard/api",
  PUBLIC_BASE_URL: _baseUrl,
      /* "start": "set HTTPS=true&&set SSL_CRT_FILE=C:/Windows/System32/cert.crt&&set SSL_KEY_FILE=C:/Windows/System32/cert.key&&craco start",*/

  PRIVET_BASE_URL: "",
};
export default BASE_URL;
