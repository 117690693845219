import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import langReducer from "@/features/localization/localizationSlice";
import authToken from "@/features/auth/authTokenSlice";
import globalSearch from "@/features/globalSearch/globalSearchSlice";
export const store = configureStore({
  reducer: { langReducer, authToken, globalSearch },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
