import { Provider } from "react-redux";
import { store } from "./app/store";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CssBaseline } from "@mui/material";
import Routes from "./Routes";
import MuiTheme from "./libs/mui/theme";
import { useGetPermissionsTreeByRoleQuery } from "./api/permissions/usePermissionsQueries";
import createAbility from "./libs/casl/ability";
import { AbilityContext } from "./libs/casl/can";
import { isArray } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Wrapper = () => {
  const userData: any = localStorage.getItem("userData") ?? "{}";
  const userRoles: any = JSON.parse(userData)?.roles;

  const {
    data: permission,
    isLoading: isPermissionLoading,
    isError: isPermissionError,
  } = useGetPermissionsTreeByRoleQuery({

    params: {
      id: isArray(userRoles) && userRoles.length ? userRoles[0]?.id : "",
    },
  });
  const ability = createAbility({
    permission,
    isPermissionLoading,
    isPermissionError,
  });
  return (
    <Provider store={store}>
      <CssBaseline />
      <MuiTheme>
        <AbilityContext.Provider value={ability}>
          <Routes />
          <ToastContainer />
        </AbilityContext.Provider>
      </MuiTheme>
      <ReactQueryDevtools />
    </Provider>
  );
};

export default Wrapper;
