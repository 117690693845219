import { TPermissionTreeResponseByRole } from "@/api/permissions/type";
import { TGenericResponse } from "@/api/type";
import { defineAbility } from "@casl/ability";
import { useNavigate } from "react-router-dom";

const createAbility = ({
  permission,
  isPermissionLoading,
  isPermissionError,
}: {
  permission: TGenericResponse<TPermissionTreeResponseByRole> | undefined;
  isPermissionLoading: boolean;
  isPermissionError: boolean;
}) => {
  return defineAbility((can, cannot) => {
    permission?.response.permissions.data.forEach((permission) =>
      permission.children.forEach((childPermission) => {
        const permission = childPermission.name.split(".");
        //see if permission is has shape subject.action then define ability
        if (permission.length === 2) can(permission[1], permission[0]);
      })
    );
  });
};

export default createAbility;
