import API_ROUTES from "@/constants/apiRoutes";
import apiInstance from "../apiInstance";
import {
  TGenericResponse,
  TGenericPaginationParams,
  TGenericRequestPayload,
  TIdParam,
} from "../type";
import {
  TPermission,
  TPermissionParentResponse,
  TPermissionRequestData,
  TPermissionResponse,
  TPermissionResponseByRole,
  TPermissionTreeResponseByRole,
  TResponse,
} from "./type";

const getPermissions = async (
  payload: TGenericRequestPayload<null, TGenericPaginationParams>
) => {
  const { data } = await apiInstance.get<TGenericResponse<TResponse>>(
    API_ROUTES.PERMISSIONS.GET_PERMISSIONS,
    { params: payload.params }
  );
  return data;
};
const getPermissionsParent = async (
  payload: TGenericRequestPayload<null, { with_children?: 1 | 0 }>
) => {
  const { data } = await apiInstance.get<
    TGenericResponse<TPermissionParentResponse | TPermissionTreeResponseByRole>
  >(API_ROUTES.PERMISSIONS.GET_PERMISSIONS_PATENT, { params: payload.params });
  return data;
};
const getPermissionsTreeByRoleId = async (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { data } = await apiInstance.get<
    TGenericResponse<TPermissionTreeResponseByRole>
  >(API_ROUTES.PERMISSIONS.GET_PERMISSIONS_BY_ROLE_ID_TREE, {
    params: payload?.params,
  });
  return data;
};
const getPermissionsByRoleId = async (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { data } = await apiInstance.get<
    TGenericResponse<TPermissionResponseByRole>
  >(API_ROUTES.PERMISSIONS.GET_PERMISSIONS_BY_ROLE_ID, {
    params: payload.params,
  });
  return data;
};
const getPermission = async (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { data } = await apiInstance.get<TGenericResponse<TPermissionResponse>>(
    API_ROUTES.PERMISSIONS.GET_PERMISSION,
    { params: payload.params }
  );
  return data;
};

const addPermission = async (
  payload: TGenericRequestPayload<TPermissionRequestData, null>
) => {
  const { data } = await apiInstance.post<TGenericResponse<TPermission>>(
    API_ROUTES.PERMISSIONS.ADD_PERMISSION,
    payload.body
  );
  return data;
};
const updatePermission = async (
  payload: TGenericRequestPayload<TPermissionRequestData, null>
) => {
  const { data } = await apiInstance.post<TGenericResponse<TPermission>>(
    API_ROUTES.PERMISSIONS.UPDATE_PERMISSION,
    payload.body
  );
  return data;
};
const deletePermission = async (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { data } = await apiInstance.get<TGenericResponse<string>>(
    API_ROUTES.PERMISSIONS.DELETE_PERMISSION,
    { params: payload.params }
  );
  return data;
};
export {
  getPermissions,
  getPermissionsParent,
  getPermissionsTreeByRoleId,
  getPermissionsByRoleId,
  getPermission,
  addPermission,
  updatePermission,
  deletePermission,
};
