import BASE_URL from "@/constants/domin";
import axios from "axios";

const apiInstance = axios.create({ baseURL: BASE_URL.PUBLIC_BASE_URL });
apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
    }
    config.headers.Accept = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.replace("/login");
    }
    Promise.reject(error);
  }
);
export default apiInstance;
