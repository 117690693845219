import { RootState } from "@/app/store";
import { createSlice } from "@reduxjs/toolkit";
const authTokenSlice = createSlice({
  name: "token",
  initialState: { token: "" },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});
export default authTokenSlice.reducer;
export const { setToken } = authTokenSlice.actions;
export const selectToken = (state: RootState) => state.authToken.token;
