import { useNavigate } from "react-router-dom";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  addPermission,
  deletePermission,
  getPermission,
  getPermissions,
  getPermissionsByRoleId,
  getPermissionsParent,
  getPermissionsTreeByRoleId,
  updatePermission,
} from ".";
import {
  TGenericPaginationParams,
  TGenericRequestPayload,
  TIdParam,
} from "../type";
import { TPermissionRequestData } from "./type";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useGetPermissionsInfiniteQuery = (
  payload: TGenericRequestPayload<null, TGenericPaginationParams>
) => {
  const { params } = payload;
  return useInfiniteQuery({
    queryKey: [
      "permissions-infinite-query",
      params.search_input,
      params.order_by,
      params.type,
      params.limit,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getPermissions({
        params: {
          pagination: 1,
          page: pageParam,
          ...params,
        },
      }),
    getNextPageParam: (lastPage, pages) => {
      //   return (
      //     lastPage.response.permissions.current_page !==
      //     pages[0].response.permissions.last_page
      //   );
      return true;
    },
  });
};
const useGetPermissionsParentQuery = (
  payload: TGenericRequestPayload<null, { with_children?: 1 | 0 }>
) => {
  return useQuery({
    queryKey: ["get-permissions-parent-query"],
    queryFn: () => getPermissionsParent(payload),
  });
};
const useGetPermissionsTreeByRoleQuery = (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { params } = payload;

  return useQuery({
    queryKey: [`get-permissions-tree-by-role-${params?.id}-query`],
    queryFn: () => getPermissionsTreeByRoleId(payload),
    cacheTime: 0,
    enabled: !!(params?.id && localStorage.getItem("token")),
  });
};
const useGetPermissionsByRoleQuery = (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { params } = payload;
  return useQuery({
    queryKey: [`get-permissions-by-role-${params?.id}-query`],
    queryFn: () => getPermissionsByRoleId(payload),
    cacheTime: 0,
    enabled: !!params?.id,
  });
};
const useGetPermissionQuery = (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { params } = payload;
  return useQuery({
    queryKey: ["get-permission-query", params.id],
    queryFn: () => getPermission(payload),
    cacheTime: 0,
    enabled: !!params?.id,
  });
};
const useAddPermissionMutation = () => {
  return useMutation({
    mutationKey: ["add-permission-mutation"],
    mutationFn: (
      payload: TGenericRequestPayload<TPermissionRequestData, null>
    ) => addPermission(payload),
  });
};

const useUpdatePermissionMutation = (
  payload: TGenericRequestPayload<null, TIdParam>
) => {
  const { params } = payload;
  return useMutation({
    mutationKey: [`update-Permission-${params.id}-mutation`],
    mutationFn: (
      payload: TGenericRequestPayload<TPermissionRequestData, null>
    ) => updatePermission(payload),
  });
};
const useDeletePermissionMutation = () => {
  const query = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: [`delete-Permission-mutation`],
    mutationFn: (payload: TGenericRequestPayload<null, TIdParam>) =>
      deletePermission(payload),
    onSuccess(data, variables, context) {
      query.refetchQueries(["permissions-infinite-query"]);
      toast.success(t("common.deleted-successfully"));
    },
  });
};
export {
  useGetPermissionsInfiniteQuery,
  useGetPermissionsParentQuery,
  useGetPermissionsTreeByRoleQuery,
  useGetPermissionsByRoleQuery,
  useGetPermissionQuery,
  useAddPermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation,
};
