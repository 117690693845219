import { lazy, Suspense, useContext } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import App from "./App";
import { useAppSelector } from "./app/hooks";
import { selectToken } from "./features/auth/authTokenSlice";
import { AbilityContext } from "./libs/casl/can";
import Forbidden from "./components/others/forbidden";
import { useTranslation } from "react-i18next";
import Loading from "./components/others/loading";

const Chat = lazy(() => import("./pages/chat"));
const PricesReports = lazy(() => import("@/pages/reports/pricesReports"));
const MainReports = lazy(() => import("@/pages/reports/mainReports"));
const EmailsReports = lazy(() => import("@/pages/reports/emailsReports"));
const PlaysReports = lazy(() => import("@/pages/reports/playsReports"));
const Login = lazy(() => import("./pages/login"));
const ApplicationUser = lazy(() => import("./pages/applicationUsers/index"));
const ReferralExceptions = lazy(() => import("./pages/exceptions/index"));
const Admins = lazy(() => import("./pages/admins"));
const AddEditAdmin = lazy(() => import("./pages/admins/AddEditAdmin"));
const SuperAdmins = lazy(() => import("./pages/superAdmins"));
const AddEditSuperAdmin = lazy(
  () => import("./pages/superAdmins/AddEditSuperAdmin")
);
const Users = lazy(() => import("./pages/users/index"));
const BlockedUsers = lazy(() => import("./pages/blockedUsers"));
const AddEditUser = lazy(() => import("./pages/users/AddEditUser"));
const Countries = lazy(() => import("./pages/countries/index"));
const AddEditCountry = lazy(() => import("./pages/countries/AddEditCountry"));
// const AddDiscount = lazy(() => import('./pages/countries/addDiscount/index'))
// const AddPrize = lazy(() => import('./pages/countries/addPrize/index'))
const Layout = lazy(() => import("./layout/Layout"));
const Home = lazy(() => import("./pages/home/Index"));
// const SendGiftToUser = lazy(
//   () => import('./pages/applicationUsers/sendGiftToUser/index')
// )

// const AddRegister = lazy(() => import('./pages/countries/addRegister/index'))
const Groups = lazy(() => import("./pages/products/groups"));
const AddEditGroup = lazy(() => import("./pages/products/groups/ActionsGroup"));
const Products = lazy(() => import("./pages/products/products/index"));
const AddEditProduct = lazy(
  () => import("./pages/products/products/AddEditProduct")
);
const AddNotification = lazy(
  () => import("./pages/Notifications/AddNotification")
);

const ProductsCategories = lazy(
  () => import("./pages/products/productsCategories/index")
);
const AddEditProductsCategory = lazy(
  () => import("./pages/products/productsCategories/AddEditProductsCategory")
);
const Companies = lazy(() => import("./pages/products/companies/index"));
const AddEditCompany = lazy(
  () => import("./pages/products/companies/ActionsCompany")
);
const Cards = lazy(() => import("./pages/products/cards"));
const AddEditCards = lazy(
  () => import("./pages/products/cards/ActionsCard/AddEditCard")
);

const Orders = lazy(() => import("./pages/orders/orders"));
const PausedOrders = lazy(() => import("./pages/orders/paused"));
const ArchiveOrders = lazy(() => import("./pages/orders/archive"));

const Settings = lazy(() => import("./pages/settings"));
const AddEditSetting = lazy(() => import("./pages/settings/AddEditSetting"));

const PreviewCategories = lazy(
  () => import("./pages/categories/PreviewCategories")
);
const AddEditGift = lazy(() => import("./pages/gifts/AddEditGift/index"));
const PreviewGift = lazy(() => import("./pages/gifts/previewGift"));
const PreviewAdWall = lazy(() => import("./pages/adsWall/PreviewAdWall"));

const AddEditAdWall = lazy(() => import("./pages/adsWall/AddEditAdsWall"));
const Category = lazy(() => import("./pages/categories/index"));
const AddEditCategory = lazy(
  () => import("./pages/categories/AddEditCategory")
);
const Competitions = lazy(() => import("./pages/competitions/index"));
const ArchiveCompetitions = lazy(
  () => import("./pages/archiveCompetitions/index")
);
const AddEditCompetition = lazy(
  () => import("./pages/competitions/addEditCompetition")
);
const Gifts = lazy(() => import("./pages/gifts/index"));
const Roles = lazy(() => import("./pages/roles"));
const AddUpdateRole = lazy(() => import("./pages/roles/addUpdate"));
const PreviewRole = lazy(() => import("./pages/roles/preview"));
const Permissions = lazy(() => import("./pages/permissions"));
const AdsWall = lazy(() => import("./pages/adsWall"));
const BuildNumbers = lazy(() => import("./pages/buildNumbers"));
const AddEditBuildNumber = lazy(
  () => import("./pages/buildNumbers/addEditBuildNumber")
);
const Ads = lazy(() => import("./pages/Ad"));

const AddEditAd = lazy(() => import("./pages/Ad/AddEditAd/index"));
const AddEditMessageTemplate = lazy(
  () => import("./pages/messageTemplate/addEditMessageTemplate")
);
const PreviewMessageTemplate = lazy(
  () => import("./pages/messageTemplate/previewMessageTemplate")
);
const PreviewAd = lazy(() => import("./pages/Ad/PreviewAd"));
const PreviewCollection = lazy(
  () => import("./pages/collection/previewCollection")
);
const AddEditCollection = lazy(
  () => import("./pages/collection/AddEditCollection/index")
);
const DeleteOrders = lazy(() => import("./pages/deleteOrders"));
const Collections = lazy(() => import("./pages/collection/index"));
const Notifications = lazy(() => import("./pages/Notifications/index"));
const MessageTemplates = lazy(() => import("./pages/messageTemplate/index"));
const PreviewPermission = lazy(() => import("./pages/permissions/preview"));
const AddUpdatePermission = lazy(() => import("./pages/permissions/addUpdate"));

const Tasks = lazy(() => import("./pages/tasks/index"));
const AddEditTask = lazy(() => import("./pages/tasks/addEditTask/index"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const BlockedEmails = lazy(() => import("./pages/blockedEmails"));
const BlockedDevices = lazy(() => import("./pages/blockedDevices"));
const SpecialOffers = lazy(() => import("./pages/specialOffers"));
const AddUpdateSpecialOffers = lazy(
  () => import("./pages/specialOffers/addUpdate")
);
const Routes = () => {
  const { t } = useTranslation();
  const token = useAppSelector(selectToken);
  const ability = useContext(AbilityContext);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route path="/login" element={<Login />} />

        <Route
          path="/chat"
          element={
            ability.can("index", "dashboard_api_chat") ? (
              <Chat />
            ) : (
              <Forbidden />
            )
          }
        />

        <Route
          path="/chat/:userId"
          element={
            ability.can("index", "dashboard_api_chat") ? (
              <Chat />
            ) : (
              <Forbidden />
            )
          }
        />

        {/* {token && ( */}
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              ability.can("index", "dashboard_api_home") ? (
                <Home />
              ) : (
                <Forbidden />
              )
            }
          />

          <Route path="/chat/:userId" element={<Chat />} />
          <Route
            path="/profile/:profileId"
            element={
              ability.can("index", "dashboard_api_app_profile") ? (
                <Profile />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/deleteOrders"
            element={
              ability.can("index", "dashboard_api_delete_orders") ? (
                <DeleteOrders />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/blocked-emails"
            element={
              // ability.can("index", "dashboard_api_blockedEmails") ? (
              <BlockedEmails />
              // ) : (
              //   <Forbidden />
              // )
            }
          />
          <Route
            path="/blocked-devices"
            element={
              // ability.can("index", "dashboard_api_blockedDeviceIds") ? (
              <BlockedDevices />
              // ) : (
              //   <Forbidden />
              // )
            }
          />

          <Route
            path="/app_users"
            element={
              ability.can("index", "dashboard_api_app_users") ? (
                <ApplicationUser />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/profile/:profileId"
            element={
              ability.can("profile", "dashboard_api_app_users") ? (
                <Profile />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="reports/prices-reports"
            element={
              ability.can("index", "dashboard_api_prices_reports") ? (
                <PricesReports />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="reports/main-reports"
            element={
              // ability.can("index", "dashboard_api_main_reports") ? (
              <MainReports />
              // ) : (
              //   <Forbidden />
              // )
            }
          />
          <Route
            path="reports/emails-reports"
            element={
              // ability.can("index", "dashboard_api_emails_reports") ? (
              <EmailsReports />
              // ) : (
              //   <Forbidden />
              // )
            }
          />
          <Route
            path="reports/plays-reports"
            element={
              // ability.can("index", "dashboard_api_plays_reports") ? (
              <PlaysReports />
              // ) : (
              //   <Forbidden />
              // )
            }
          />
          {/* <Route path="/app_users/gift/:userId" element={<SendGiftToUser />} /> */}
          <Route
            path="/user-referral-exception"
            element={
              ability.can("index", "dashboard_api_user-referral-exception") ? (
                <ReferralExceptions />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/Ad"
            element={
              ability.can("index", "dashboard_api_ads") ? (
                <Ads />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/special-offers"
            element={
              ability.can("index", "dashboard_api_special_offers") ? (
                <SpecialOffers />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/special-offer/add"
            element={
              ability.can("store", "dashboard_api_special_offers") ? (
                <AddUpdateSpecialOffers />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/special-offer/:id/update"
            element={
              ability.can("update", "dashboard_api_special_offers") ? (
                <AddUpdateSpecialOffers />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/users"
            element={
              ability.can("index", "dashboard_api_users") ? (
                <Users />
              ) : (
                <Forbidden />
              )
            }
          />

          {/* permission */}
          <Route
            path="/blockedUsers"
            element={
              ability.can("index", "dashboard_api_blocked_users") ? (
                <BlockedUsers />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/ads-wall"
            element={
              ability.can("index", "dashboard_api_ad_walls") ? (
                <AdsWall />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/collection"
            element={
              ability.can("index", "dashboard_api_collections") ? (
                <Collections />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/message-templates"
            element={
              ability.can("index", "dashboard_api_message_templates") ? (
                <MessageTemplates />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/ads-wall/preview-ad-wall/:id"
            element={
              ability.can("update", "dashboard_api_ad_walls") ? (
                <PreviewAdWall />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/Notifications/AddNotifications"
            element={
              ability.can("store", "dashboard_api_notifications") ? (
                <AddNotification />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/categories/PreviewCategories/:id"
            element={
              ability.can("view", "category") ? (
                <PreviewCategories />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/gifts/addGift"
            element={
              ability.can("store", "dashboard_api_gifts") ? (
                <AddEditGift />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/gifts/editGift/:giftId"
            element={
              ability.can("update", "dashboard_api_gifts") ? (
                <AddEditGift />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/Ad/editAd/:AdId"
            element={
              ability.can("update", "dashboard_api_ads") ? (
                <AddEditAd />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/Ad/addAd/"
            element={
              ability.can("store", "dashboard_api_ads") ? (
                <AddEditAd />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/message-templates/add-message-template"
            element={
              ability.can("store", "dashboard_api_message_templates") ? (
                <AddEditMessageTemplate />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/Notifications"
            element={
              ability.can("index", "dashboard_api_notifications") ? (
                <Notifications />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/message-templates/edit-message-template/:id"
            element={
              ability.can("update", "dashboard_api_message_templates") ? (
                <AddEditMessageTemplate />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/collection/EditCollection/:collectionId"
            element={
              ability.can("update", "dashboard_api_collections") ? (
                <AddEditCollection />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/collection/AddCollection"
            element={
              ability.can("store", "dashboard_api_collections") ? (
                <AddEditCollection />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/Ad/previewAd/:id"
            element={
              ability.can("update", "dashboard_api_ads") ? (
                <PreviewAd />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/message-templates/preview/:id"
            element={
              ability.can("update", "dashboard_api_message_templates") ? (
                <PreviewMessageTemplate />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/gifts/previewGift/:id"
            element={
              ability.can("update", "dashboard_api_gifts") ? (
                <PreviewGift />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/collection/previewCollection/:id"
            element={
              ability.can("update", "dashboard_api_collections") ? (
                <PreviewCollection />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/users/add-user"
            element={
              ability.can("store", "dashboard_api_users") ? (
                <AddEditUser />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/users/edit-user/:userId"
            element={
              ability.can("update", "dashboard_api_users") ? (
                <AddEditUser />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/admins"
            element={
              ability.can("index", "dashboard_api_admins") ? (
                <Admins />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/admins/add-admin"
            element={
              ability.can("store", "dashboard_api_admins") ? (
                <AddEditAdmin />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/admins/edit-admin/:adminId"
            element={
              ability.can("update", "dashboard_api_admins") ? (
                <AddEditSuperAdmin />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/super-admins"
            element={
              ability.can("index", "dashboard_api_super_admins") ? (
                <SuperAdmins />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/super-admins/add-super-admin"
            element={
              ability.can("store", "dashboard_api_super_admins") ? (
                <AddEditSuperAdmin />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/super-admins/edit-super-admin/:superAdminId"
            element={
              ability.can("update", "dashboard_api_super_admins") ? (
                <AddEditSuperAdmin />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/categories"
            element={
              ability.can("index", "category") ? <Category /> : <Forbidden />
            }
          />
          <Route
            path="/countries"
            element={
              ability.can("index", "dashboard_api_countries") ? (
                <Countries />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/countries/addCountry"
            element={
              ability.can("store", "dashboard_api_countries") ? (
                <AddEditCountry />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/countries/editCountry/:countryId"
            element={
              ability.can("update", "dashboard_api_countries") ? (
                <AddEditCountry />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/groups"
            element={
              ability.can("index", "dashboard_api_groups") ? (
                <Groups />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/groups/addGroup"
            element={
              ability.can("store", "dashboard_api_groups") ? (
                <AddEditGroup />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/groups/editGroup/:groupId"
            element={
              ability.can("update", "dashboard_api_groups") ? (
                <AddEditGroup />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/products"
            element={
              ability.can("index", "dashboard_api_categories") ? (
                <Products />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/products/addProduct"
            element={
              ability.can("store", "dashboard_api_categories") ? (
                <AddEditProduct />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/products/editProduct/:productId"
            element={
              ability.can("update", "dashboard_api_categories") ? (
                <AddEditProduct />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/productsCategories"
            element={
              ability.can("index", "dashboard_api_prices") ? (
                <ProductsCategories />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/productsCategories/addCategory"
            element={
              ability.can("store", "dashboard_api_prices") ? (
                <AddEditProductsCategory />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/productsCategories/editCategory/:categoryId"
            element={
              ability.can("update", "dashboard_api_prices") ? (
                <AddEditProductsCategory />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/companies"
            element={
              ability.can("index", "dashboard_api_companies") ? (
                <Companies />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/companies/addCompany"
            element={
              ability.can("store", "dashboard_api_companies") ? (
                <AddEditCompany />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/companies/editCompany/:companyId"
            element={
              ability.can("update", "dashboard_api_companies") ? (
                <AddEditCompany />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/roles"
            element={
              ability.can("index", "dashboard_api_roles") ? (
                <Roles />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/roles/role/add"
            element={
              ability.can("store", "dashboard_api_roles") ? (
                <AddUpdateRole />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/roles/role/:roleId/update"
            element={
              ability.can("update", "dashboard_api_roles") ? (
                <AddUpdateRole />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/roles/role/:roleId/preview"
            element={
              ability.can("update", "dashboard_api_roles") ? (
                <PreviewRole />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/permissions"
            element={
              ability.can("index", "dashboard_api_permission") ? (
                <Permissions />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/categories/addCategory"
            element={
              ability.can("store", "category") ? (
                <AddEditCategory />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/ads-wall/edit-ad-wall/:AdWallId"
            element={
              ability.can("update", "dashboard_api_ad_walls") ? (
                <AddEditAdWall />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/ads-wall/add-ad-wall"
            element={
              ability.can("store", "dashboard_api_ad_walls") ? (
                <AddEditAdWall />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/categories/editCategory/:categoryId"
            element={
              ability.can("update", "category") ? (
                <AddEditCategory />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/gifts"
            element={
              ability.can("index", "dashboard_api_gifts") ? (
                <Gifts />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/competitions"
            element={
              ability.can("index", "dashboard_api_competitions") ? (
                <Competitions />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/competitions/addCompetition"
            element={
              ability.can("store", "dashboard_api_competitions") ? (
                <AddEditCompetition />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/competitions/editCompetition/:competitionId"
            element={
              ability.can("update", "dashboard_api_competitions") ? (
                <AddEditCompetition />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/archive_competitions"
            element={
              ability.can("index", "dashboard_api_archive_competitions") ? (
                <ArchiveCompetitions />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/settings"
            element={
              ability.can("index", "dashboard_api_settings") ? (
                <Settings />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/settings/addSetting"
            element={
              ability.can("store", "dashboard_api_settings") ? (
                <AddEditSetting />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/settings/editSetting/:settingId"
            element={
              ability.can("update", "dashboard_api_settings") ? (
                <AddEditSetting />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/build-numbers"
            element={
              ability.can("index", "dashboard_api_build_numbers") ? (
                <BuildNumbers />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/build-numbers/build-number/add"
            element={
              ability.can("store", "dashboard_api_build_numbers") ? (
                <AddEditBuildNumber />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/build-numbers/build-number/:buildNumberId/edit"
            element={
              ability.can("update", "dashboard_api_build_numbers") ? (
                <AddEditBuildNumber />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/permissions/permission/add"
            element={
              ability.can("store", "dashboard_api_permission") ? (
                <AddUpdatePermission />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/permissions/permission/:permissionId/update"
            element={
              ability.can("update", "dashboard_api_permission") ? (
                <AddUpdatePermission />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/permissions/permission/:permissionId/preview"
            element={
              ability.can("update", "dashboard_api_permission") ? (
                <PreviewPermission />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/permissions/permission/:permissionId/preview"
            element={<PreviewPermission />}
          />
          {/* <Route
            path="/countries/addDiscount"
            element={
              ability.can('add_discount', 'dashboard_api_countries') ? (
                <AddDiscount />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/countries/addPrize"
            element={
              ability.can('add_prize', 'dashboard_api_countries') ? (
                <AddPrize />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/countries/addRegister"
            element={
              ability.can('add_register', 'dashboard_api_countries') ? (
                <AddRegister />
              ) : (
                <Forbidden />
              )
            }
          /> */}
          <Route
            path="/tasks"
            element={
              ability.can("index", "dashboard_api_tasks") ? (
                <Tasks />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/tasks/addTask"
            element={
              ability.can("store", "dashboard_api_tasks") ? (
                <AddEditTask />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/tasks/editTask/:taskId"
            element={
              ability.can("update", "dashboard_api_tasks") ? (
                <AddEditTask />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/orders"
            element={
              ability.can("index", "dashboard_api_orders") ? (
                <Orders />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/orders/paused"
            element={
              ability.can("index", "dashboard_api_paused") ? (
                <PausedOrders />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/orders/archive"
            element={
              ability.can("index", "dashboard_api_archive") ? (
                <ArchiveOrders />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/cards"
            element={
              ability.can("index", "dashboard_api_cards") ? (
                <Cards />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/cards/addCard"
            element={
              ability.can("store", "dashboard_api_cards") ? (
                <AddEditCards />
              ) : (
                <Forbidden />
              )
            }
          />
          <Route
            path="/cards/editCard/:cardId"
            element={
              ability.can("update", "dashboard_api_cards") ? (
                <AddEditCards />
              ) : (
                <Forbidden />
              )
            }
          />
        </Route>
      </Route>
    )
  );
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
