import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
type TLang = "en" | "ar";
type TLangState = { lang: TLang };
const initialState: TLangState = { lang: "en" };
export const localizationSlice = createSlice({
  name: "localization",
  initialState,
  reducers: {
    switchLang: (state, action: PayloadAction<TLang>) => {
      state.lang = action.payload;
    },
  },
});
export const { switchLang } = localizationSlice.actions;
export const selectLang = (state: RootState) => state.langReducer.lang;
export default localizationSlice.reducer;
