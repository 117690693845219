import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectLang } from "./features/localization/localizationSlice";
import RTL from "./libs/mui/rtl";
import "./App.css";
import { setToken } from "./features/auth/authTokenSlice";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const lang = useAppSelector(selectLang);

  useEffect(() => {
    if (localStorage.token) {
      dispatch(setToken(localStorage.token));
    } else navigate("/login");
  }, [dispatch, navigate]);

  return (
    <>
      {lang === "ar" ? (
        <RTL>
          <Outlet />
        </RTL>
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default App;
