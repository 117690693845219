import { RootState } from "@/app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const name = "globalSearch";
type TState = {
  search_input?: string;
  phone_search_input?: string | number;
};
const initialState: TState = {
  search_input: "",
  phone_search_input: "",
};
const globalSearchSlice = createSlice({
  name,
  initialState,
  reducers: {
    setGlobalSearch: (state, action: PayloadAction<TState>) => {
      return action.payload;
    },
  },
});
export const selectGlobalSearch = (state: RootState) => state.globalSearch;
export const { setGlobalSearch } = globalSearchSlice.actions;
export default globalSearchSlice.reducer;
