import React, { FC, PropsWithChildren } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { useAppSelector } from "@/app/hooks";
import { selectLang } from "@/features/localization/localizationSlice";
const MuiTheme: FC<PropsWithChildren<{}>> = ({ children }) => {
  const lang = useAppSelector(selectLang);
  const theme = createTheme({
    direction: lang === "ar" ? "rtl" : "ltr",
    palette: {
      primary: { main: "#3a3657" },
    },
    components: {
      MuiMenu: {
        defaultProps: {
          disableScrollLock: true,
        },
      },

      MuiDialog: {
        defaultProps: {
          disableScrollLock: true,
        },
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
