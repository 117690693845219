const AUTH = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  CLEARSTATISTICS: "/clear-users-statistics",
};

const APP_USERS = {
  GET_APP_USERS: "/app-users/get",
  EXCEPTION_USER: "/user-referral-exception/add",
  UN_EXCEPTION_USER: "/user-referral-exception/remove",
};

const BLOCKED_USERS = {
  GET_BLOCKED_USERS: "/app-users/blocked-get",
};

const HOME = {
  GET: "/home/get",
  HOME_PIE: "/home-pie/get",
  HOME_BAR: "/home-bar/get",
  USERS_BAR: "/home-users-bar/get",
};
const CATEGORIES = {
  GET_CATEGORIES: "/categories/get",
  DELETE_GATEGORIES: "/category/delete",
  GET_CATEGORY: "/category/get",
  ADD_CATEGORY: "/category/store",
  UPDATE_CATEGORY: "/category/update",
  GET_BY_COMPANY: "/category/get-by-company",
};
const COMPETITIONS = {
  GET_COMPETITIONS: "/competitions/get",
  GET_COMPETITION: "/competition/get",
  DELETE_COMPETITIONS: "/competition/delete",
  ADD_COMPETITION: "/competition/store",
  UPDATE_COMPETITION: "/competition/update",
  ARCHIVE_COMPETITION: "/competition/archive",
  UNARCHIVE_COMPETITION: "/competition/un_archive",
  ACTIVE_COMPETITION: "/competition/active",
  UNACTIVE_COMPETITION: "/competition/un_active",
};
const ARCHIVE_COMPETITIONS = {
  GET_ARCHIVE_COMPETITIONS: "/competitions/archive/get",
  UN_ARCHIVE_COMPETITIONS: "/competition/un_archive",
};
const REFERRALS_EXCEPTIONS = {
  GET_REFERRALS_EXCEPTIONS: "/user-referrals-exceptions/get",
  REMOVE_REFERRALS_EXCEPTION: "/user-referral-exception/remove",
};

const ROLES = {
  GET_ROLES: "/roles/get",
  GET_ROLE: "role/get",
  ADD_ROLE: "role/store",
  UPDATE_ROLE: "role/update",
  DELETE_ROLE: "role/delete",
};
const MESSAGE_TEMPLATES = {
  GET_MESSAGE_TEMPLATES: "message_templates/get",
  GET_MESSAGE_TEMPLATE: "message_template/get",
  DELETE_MESSAGE_TEMPLATES: "/message_template/delete",
  UPDATE_MESSAGE_TEMPLATES: "/message_template/update",
  ADD_MESSAGE_TEMPLATES: "message_template/store",
};
const SETTINGS = {
  GET_SETTINGS: "/settings/get",
  GET_SETTING: "/setting/get",
  ADD_SETTING: "/setting/store",
  UPDATE_SETTING: "/setting/update",
  DELETE_SETTING: "/setting/delete",
};

const BUILD_NUMBERS = {
  GET_BUILD_NUMBERS: "/build_numbers/get",
  GET_BUILD_NUMBER: "/build_number/get",
  ADD_BUILD_NUMBER: "/build_number/store",
  UPDATE_BUILD_NUMBER: "/build_number/update",
  DELETE_BUILD_NUMBER: "/build_number/delete",
  DELETE_SETTING: "/setting/delete",
};

const PERMISSIONS = {
  GET_PERMISSIONS: "/permissions/get",
  GET_PERMISSIONS_PATENT: "/permissions/parent/get",
  GET_PERMISSIONS_BY_ROLE_ID: "/permissions/get/by/role",
  GET_PERMISSIONS_BY_ROLE_ID_TREE: "/permissions/get/by/role/tree",
  GET_PERMISSION: "/permission/get",
  ADD_PERMISSION: "/permission/store",
  UPDATE_PERMISSION: "/permission/update",
  DELETE_PERMISSION: "/permission/delete",
};
const GIFTS = {
  GET: "/gifts/get",
  GET_GIFT: "/gift/get",
  DELETE: "/gift/delete",
  UPDATE: "/gift/update",
  ADD: "/gift/store",
  SEND: "/send-gift-to-user",
};
const COLLECTIONS = {
  GET: "/collections/get",
  GET_COLLECTION: "/collection/get",
  DELETE: "/collection/delete",
  UPDATE: "/collection/update",
  ADD: "/collection/store",
};
const AD_WALLS = {
  GET: "/ad_walls/get",
  GET_AD_WALL: "/ad_wall/get",
  DELETE: "/ad_wall/delete",
  UPDATE: "/ad_wall/update",
  ADD: "/ad_wall/store",
};
const AD = {
  GET: "/ads/get",
  GET_AD: "/ad/get",
  ADD: "/ad/store",
  UPDATE: "/ad/update",
  DELETE: "/ad/delete",
};
const PRODUCTS_CATEGORIES = {
  GET: "/prices/get",
  GET_BY_ID: "/price/get",
  ADD: "/price/store",
  UPDATE: "/price/update",
  DELETE: "/price/delete",
};
const COUNTRIES = {
  GET: "/countries/get",
  GET_COUNTRY: "/country/get",
  GET_COUNTRIES_BY_COLLECTION_ID: "countries/by/collection/get",
  UPDATE_COUNTRY: "/country/update",
  ADD_DISCOUNT: "/countries/add-discount",
  ADD_PRIZE: "/countries/add-prize",
  ADD_REGISTER: "/countries/add-register-prize",
};
const NOTIFICATIONS = {
  GET: "/notifications/get",

  ADD: "/notification/store",
};
const BLOCKED_EMAIL = {
  GET_ALL: "/blocked-emails/get",
  GET_ONE: "/blocked-email/get",
  UPDATE: "/blocked-email/update",
  STORE: "/blocked-email/store",
  DELETE: "/blocked-email/delete",
  REMOVE: "/blocked-email/remove",
};
const BLOCKED_DEVICE = {
  GET_ALL: "/device-ids/get",
  GET_ONE: "/device-id/get",
  UPDATE: "/device-id/update",
  STORE: "/device-id/store",
  DELETE: "/device-id/delete",
  REMOVE: "/device-id/remove",
};
const PROFILE = {
  GET_PROFILE: "/app-user/profile",
  DELETE_PROFILE: "/app-user/delete",
  BLOCK_PROFILE: "/app-user/block",
  UNBLOCK_PROFILE: "/app-user/unblock",
  CHANGE_POINT: "/app-user/change-points",
  CHANGE_INFORMATION: "/app-user/change-information",
  BLOCK_INFORMATION: "/app-user/block-information",
  UN_BLOCK_INFORMATION: "/app-user/un-block-information",
  CHANGE_PHONE: "/app-user/change-phone",
  CHANGE_COUNTRY: "/app-user/change-country",
};
const DELETE_ORDERS = {
  GET: "/delete-orders/get",
  CONFIRM: "/delete-order/confirm",
  REJECT: "/delete-order/reject",
  DELETE_ALL: "/delete-order/delete-all",
};
const USERS = {
  USERS: {
    GET: "/users/get",
    GET_BY_ID: "/user/get",
    ADD: "/user/store",
    UPDATE: "/user/update",
    DELETE: "/user/delete",
  },
  ADMIN: {
    GET: "/admins/get",
    GET_BY_ID: "/admin/get",
    ADD: "/admin/store",
    UPDATE: "/admin/update",
    DELETE: "/admin/delete",
  },

  SUPER_ADMIN: {
    GET: "/super/admins/get",
    GET_BY_ID: "/super/admin/get",
    ADD: "/super/admin/store",
    UPDATE: "/super/admin/update",
    DELETE: "/super/admin/delete",
  },
};

const REPORTS = {
  MAIN: {
    GET: "main/report/get",
  },
  PLAY: {
    GET: "/play-info/report/get",
  },
  USER_EMAILS: {
    GET: "/user-emails/report/get",
  },
  PRICES_REPORTS: {
    GET: "/orders-count/get-by-prices",
    GET_CSV_FILE: "/order-count/get-csv-file",
  },
};
const TASKS = {
  GET_TASKS: "/tasks/get",
  GET_TASK: "/task/get",
  ADD_TASK: "/task/store",
  EDIT_TASK: "/task/update",
  DELETE_TASK: "/task/delete",
};
const PRODUCTS = {
  GROUPS: {
    GET: "/groups/get",
    GET_BY_ID: "/group/get",
    ADD: "/group/store",
    UPDATE: "/group/update",
    DELETE: "/group/delete",
  },
  COMPANIES: {
    GET: "/companies/get",
    GET_BY_ID: "/company/get",
    ADD: "/company/store",
    UPDATE: "/company/update",
    DELETE: "/company/delete",
  },
  CATEGORIES: {
    GET: "/prices/get",
    GET_BY_ID: "/price/get",
    ADD: "/price/store",
    UPDATE: "/price/update",
    DELETE: "/price/delete",
    GET_BY_CATEGORIES: "/prices/get-by-categories",
  },
  CARDS: {
    GET: "/cards/get",
    GET_BY_ID: "/card/get",
    ADD: "/card/store",
    UPDATE: "/card/update",
    DELETE: "/card/delete",
  },
};

const ORDERS = {
  GET_PUBLIC: "/orders/get",
  GET_PAUSED: "/paused-orders/get",
  GET_ARCHIVE: "/archived-orders/get",
  GET_ORDERS_PRICES: "/orders-prices/get",
  ACTIONS: {
    REJECT: "/order/reject",
    CONFIRM: "/order/confirm",
    PAUSE: "/order/pause",
    DELETE: "/order/delete",
    EDIT_DETAILS: "/order/edit-details",
    EDIT_NOTES: "/order/edit-notes",
    RESET: "/order/reset",
    GET_CSV_FILE: "/order/get-file",
  },
};

const CHAT = {
  GET_CHAT_PAGE: "/chat/page",
  PUT_USER_SUPPORT_FILES: "/chat/user-files-support",
  SEND_MESSAGE: "/chat/message-store",
  CHAT_TAG: "chat/add-tag",
  GET_ORDERS_COUNT: "/chat-orders-count/get",
};
const SPECIAL_OFFERS = {
  GET_ALL: "/special-offers/get",
  GET: "/special-offer/get",
  ADD: "/special-offers/store",
  UPDATE: "/special-offers/update",
  DELETE: "/special-offers/delete",
};
const API_ROUTES = {
  AUTH,
  APP_USERS,
  BLOCKED_USERS,
  REPORTS,
  CATEGORIES,
  COMPETITIONS,
  ARCHIVE_COMPETITIONS,
  REFERRALS_EXCEPTIONS,
  ROLES,
  BLOCKED_EMAIL,
  BLOCKED_DEVICE,
  SETTINGS,
  BUILD_NUMBERS,
  PERMISSIONS,
  USERS,
  HOME,
  COUNTRIES,
  DELETE_ORDERS,
  PRODUCTS,
  GIFTS,
  AD_WALLS,
  ORDERS,
  COLLECTIONS,
  AD,
  PRODUCTS_CATEGORIES,
  MESSAGE_TEMPLATES,
  NOTIFICATIONS,
  CHAT,
  TASKS,
  PROFILE,
  SPECIAL_OFFERS,
};

export default API_ROUTES;
